/* About page */

.about {
	&.pages {
		background: $white;

		.col.container {
			justify-content: flex-start;
		}
		.col.third {
			flex: 0 1 33.25%;
			margin-right: .0625%;
			h4, p {
				padding-right: 3rem;
				width: auto;
			}

			@include respond-to(small){
				flex: 0 1 100%;
				padding-bottom: $mVerticalPadding;
				h4, p {
					padding-right: 0;
				}
			}
		}
	}
}

.team .info, .about .info {
	padding-top: $verticalPadding;

	p.large-text {
		@extend .xx-large;
		@extend .gradient-text;

		margin-top: $unit;
		margin-bottom: $unit;
		padding-bottom: 0;

		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: $verticalPadding;
		}

		@include respond-to(small){
			max-width: none;
		}
	}


}

/* Block Spacing */
.block-spacing {
	&-large {
		padding-top: $verticalPadding * 3;
		padding-bottom: $verticalPadding * 3;

		img {
			margin: $verticalPadding;
		}
	}
	&-small {
		padding-top: $verticalPadding;
		padding-bottom: $verticalPadding;
		img {
			margin: $verticalPadding / 2;
		}
	}
	&-none {
		padding-top: inherit;
		padding-bottom: inherit;
		img {
			margin: inherit;
		}
	}
}

/* Border Top */

.border-top {
	border-top: 1px solid $light-grey;
}

/* Button */

button {
	border: none;
	background: $black;
	border: 1px $white solid;
	padding: 0 2em;
	border-radius: 3em;
	width: auto;

	a {
		border: none;
			color: $white;
	}
}

/* Callout */

.callout {
	@extend .span-sans;
	@extend .gradient-text;
	font-size: 2.75rem;
	font-variation-settings: "wdth" 700, "wght" 90;
	letter-spacing: 0.1em;
	margin: 0;
	padding: 0;

	@include respond-to(small){
		font-size: 8vw;
	}
}

/* Caption */

.caption {
	@extend .small;
	margin: $verticalPadding $horizontalPadding $verticalPadding 50%;
	max-width: 100vw;

	p {
		color: $dark-grey;
		max-width: none;
	}

	.light & {
		p {
			color: $white;
		}
	}

	@include respond-to (small){
		margin: $mVerticalPadding $mHorizontalPadding 0 $mHorizontalPadding;
	}
}

/* Card Page */
$buttonHeight: 15vw;

.card {
	min-height: 100vh;

	&.light .row.info h2 {
		color: $white;
		background: none;
		-webkit-text-fill-color: $white;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.col.qtr {
		p {
			padding-right: 2rem;
		}
	}

	h2 {
		font-size: 2.75rem;
	}

	.details {
		padding: $unit 0 0 0;
		display: flex;
		align-items: flex-start;
	}

	.portrait {
		width: 100%;
		height: auto;
		overflow: hidden;
		text-align: center;
		margin: 2rem 0;
	}

	.button-row {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
	}

	.social-buttons {
		display: flex;
		flex-direction: row;
	}

	.icon-button {
		flex: 0;
		border-bottom: 0;
		display: inline-flex;
		flex-direction: column;
		align-items: center;

		span {
			width: $buttonHeight;
			height: $buttonHeight;
			display: block;
			overflow: hidden;
			text-indent: -999px;
			border: 0;
			background: #333333 url("/assets/img/call.svg") center center no-repeat;
			border-radius: 6rem;	
			margin-bottom: 0.5rem;		
		}

		.calender {
			background-image: url("/assets/img/calender.svg");
		}		
		.call {
			background-image: url("/assets/img/call.svg");
		}
		.email {
			background-image: url("/assets/img/email.svg");
		}
		.facebook {
			background-image: url("/assets/img/facebook.svg");
		}
		.instagram {
			background-image: url("/assets/img/instagram.svg");
		}
		.linkedin {
			background-image: url("/assets/img/linkedin.svg");
		}
		.message {
			background-image: url("/assets/img/message.svg");
		}
		.twitter {
			background-image: url("/assets/img/twitter.svg");
		}
		.user {
			background-image: url("/assets/img/user.svg");
		}
	}

	.icon-small {
		display: inline-block;
		width: auto;
		flex: 0;
		margin-right: 1rem;
		span {
			width: $buttonHeight * 0.66;
			height: $buttonHeight * 0.66;
			border-radius: $buttonHeight * 0.66;	
		}
	}

	a.big-button {
		@extend .large;
		background: $white-gradient;
		color: #000;
		border: 0;
		border-radius: $buttonHeight;
		display: flex;
		align-items: center;
		justify-content: center;
		height: $buttonHeight;
		width: 100%;
	}

	.small {
		@extend .small;
		@extend .pitch-caps;
		padding-bottom: 1rem;
	}

	footer {
		padding: 0;
	}

	.tile h3 {
		color: #fff;
	}
	.tile p {
		color: rgba(255, 255, 255, 0.5) !important;
	}
}

/* Contact Page Styling */

.contact {
	main {

		.info {
			@include respond-to(small){
				margin-top: $mVerticalPadding;
				&:first-child {
					margin-top: 0;
				}

				.half {
					margin-bottom: 0;
				}
			}
		}

		h3 {
			@extend .small;
			@extend .pitch-caps;
		}
		a.email, a.tel {
			@extend .x-large;
			@extend .gradient-text;
			border: none;
			transition: color 0.3s ease;

			&:hover {
				color: $black;
			}

			&::before {
				@extend .small;
				@extend .pitch-caps;
				margin-left: -$unit * 1.5;
				margin-top: 0.4rem;
				position: absolute;
				color: $black;

				@include respond-to(small){
					margin-left: -$unit * 1.5;
					margin-top: 0.6rem;
					font-size: 1rem;
				}
			}

			&.tel::before {
				content: "T";
			}
			&.email::before {
				content: "E";
			}

			@include respond-to(small){
				&.tel::before, &.email::before {
					content: "";
				}
			}
		}
		address {
			@extend .x-large;
			@extend .gradient-text;
			margin-top: $unit;

			@include respond-to(small){
				font-size: 1rem;
				line-height: 1.65em;
			}
		}

		.social-networks {
			@extend .info;
			background: $rainbow;
			background-position-x: -100%;
			color: $white;
			padding: $verticalPadding $horizontalPadding;
			p {
				color: $white;
			}
			ul {
				position: static;
				margin: 1rem 0 0 0 !important;

				@include respond-to(small){
					width: 75vw;
					li {
						padding-bottom: $unit;
					}
				}

				li {
					float: left;
					margin-right: $unit * 2;
					a {
						@extend .medium;
						color: $white !important;
						border-bottom: 1px $white solid;
						transition: border-color 0.3s ease;

						&:hover {
							border-color: rgba($white, 0.0);
						}
					}
				}
			}
			@include respond-to(small){
				background-size: 400% 100%;
			}
		}
	}
}

/* Disclaimer */

.disclaimer {
	margin-top: 0;
	margin-bottom: $unit;

	@include respond-to(small){
		margin-bottom: 0.5rem;
	}
}

/* Diptych block */
.diptych-block {
	background: $x-light-grey;

	.home & {
		margin-bottom: $unit;
	}

	.col.half {
		justify-content: center;
		align-content: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include respond-to(small){
				object-fit: fill;
			}
		}

		@include respond-to(small){
			padding: 0 !important;
		}
	}

	p {
		@extend .medium;

		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}

		&.large-text {
			@extend .large;
		}
	}
	.frame {

			padding: $horizontalPadding;
			@include respond-to(small){
				margin-bottom: 0;
				padding: $mHorizontalPadding * 2 $mHorizontalPadding;
			}
	}
}

/* Edit Button */

.edit-button {
	@extend .small;
	margin: 2px $unit 0;
	position: relative;
	align-self: center;
	justify-self: center;
	color: $white;
	background: $dark-grey;
	padding: $unit/2 $unit;
	border-radius: $unit * 2;
	transition: background 0.3s ease;

	&:hover {
		cursor: pointer;
		background: $black;
	}
}

.expertise-type {
	.intro-text p {
		margin-top: 0;
	}	
}

.expertise-page {
	background: #222222;
	.intro-text {
		padding-top: 0;
	}
	.intro-text p {
		margin-top: 0;
	}
	.page-title h2, .intro-text p, ul {
		background: none !important;
		-webkit-text-fill-color: $white;
		color: $white !important;
	}
	.tile h3 {
		color: $white !important;
		border-color: rgba($white, 0.5);
	}
	.projects-set {
		background: $white;

		h2 {
			@extend .pitch-caps;
			@extend .medium;
			padding-top: $horizontalPadding;
		}

		h2, h3, p, a {
			color: $black !important;
			border-color: $black;
		}
	}
}

/* Expertise Set */
.expertise-set {
	.tile {
		h3 {
			@extend .large;
			padding-bottom: $verticalPadding;

			@include respond-to(small){
				padding-bottom: $mVerticalPadding;
			}
		}
		&:hover {
			h3 {
				text-decoration-thickness: 1px;
			}
		}
	}
}

/* Feature */

.feature {
	position: relative;
	width: 100vw;
	height: 49vw;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	background: #000;
	overflow: hidden !important;
	align-items: center;

	@include respond-to(small){
		padding-top: 22.5vw;
		height: 80vw;
	}

	.info {
		width: 100%;
		display: flex;

		@include respond-to(small){
			display: block;
			padding: $mVerticalPadding $mHorizontalPadding;
			align-self: flex-end;
		}

		.headline-left {
			@extend .span-sans;
			font-size: 1.25rem;
			letter-spacing: 0.05em;
			line-height: 1em;
			z-index: 2;
			flex: 0 1 50vw;
			align-self: center;
			padding-left: $horizontalPadding;
			transform: translate(0, -50%);
			max-width: none;
			//text-shadow: 5px 5px 20px $black;

			@include respond-to(small){
				line-height: 1.35em;
				padding-left: 0;
				text-align: left;
				margin: 0;
				padding: 0;
			}
		}

		.headline-right {
			@extend .span-sans;
			font-size: 1.25rem;
			letter-spacing: 0.05em;
			line-height: 1em;
			z-index: 2;
			flex: 0 1 50vw;
			justify-self: end;
			align-self: center;
			text-align: right;
			padding-right: $horizontalPadding;
			max-width: none;
			transform: translate(0, -50%);

			@include respond-to(small){
				line-height: 1.35em;
				text-align: left;
				margin: 0;
				padding: 0;
			}

			a {
				padding-bottom: 4px;
				border-bottom: 1px solid $white;
			}
		}

		.feature-link {
			font-size: 1rem;
			border: 1px solid $white;
			border-radius: $unit * 4;
			padding: $unit * 2.1 $unit * 2 $unit * 2 $unit * 2;
			transition: background 0.3s ease;
			background: rgba($black, 0.5);

			&:hover {
				background: $grey;
			}
		}
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include respond-to(small){
			position: absolute;
			width: auto;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.video-background {
		position: absolute;
		flex: none;
		padding-bottom: 50%;
		overflow: hidden;
		max-width: 100%;
		width: 100%;
		height: auto;
		z-index: 0;
		background: transparent no-repeat center center;
		background-size: contain;

		@include respond-to(small){
			position: relative;
			flex: 1 1 100%;
			transform: scale(2.12);
		}

		.image-background, iframe {
			position: absolute;
			top: -2;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.image-background {
			height: auto;
			top: 50%;
			transform: translateY(-50%);
			z-index: 0;
		}

		.spinner {
		  width: 40px;
		  height: 40px;
		  align-self: center;
		  justify-self: center;
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  margin: -20px -20px;
		  display: block;
			z-index: 0;
		}

		.double-bounce1, .double-bounce2 {
		  width: 100%;
		  height: 100%;
		  border-radius: 50%;
		  background-color: #fff;
		  opacity: 0.6;
		  position: absolute;
		  top: 0;
		  left: 0;

		  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
		  animation: sk-bounce 2.0s infinite ease-in-out;
		}

		.double-bounce2 {
		  -webkit-animation-delay: -1.0s;
		  animation-delay: -1.0s;
		}

		@-webkit-keyframes sk-bounce {
		  0%, 100% { -webkit-transform: scale(0.0) }
		  50% { -webkit-transform: scale(1.0) }
		}

		@keyframes sk-bounce {
		  0%, 100% {
		    transform: scale(0.0);
		    -webkit-transform: scale(0.0);
		  } 50% {
		    transform: scale(1.0);
		    -webkit-transform: scale(1.0);
		  }
		}
	}
}

/* Gradient text */

.gradient-text {
	color: $grey;
	background: $grey-gradient;
	background-size: 80% 100%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	-webkit-animation: shift 30s linear infinite;
	-moz-animation: shift 30s linear infinite;
  animation: shift 30s linear infinite;

	@-webkit-keyframes shift {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	@-moz-keyframes shift {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	@keyframes shift {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}
}

/* Gradient text: inverted */

.gradient-text-inv {
	color: $white;
	background: $white-gradient;
	background-size: 80% 100%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	-webkit-animation: shift 30s linear infinite;
	-moz-animation: shift 30s linear infinite;
  animation: shift 30s linear infinite;

	@-webkit-keyframes shift {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	@-moz-keyframes shift {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	@keyframes shift {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}
}


/* Home */

.home {
	header {
		position: relative;
		background: $black;
	}
	main {
		padding-top: 0;
	}
	.container {
		padding: $verticalPadding $horizontalPadding;
		margin-bottom: 0;
		h2 {
			@extend .x-large;
			margin: 0;
			color: $grey;
		}
		@include respond-to(small){
			padding: $mVerticalPadding $mHorizontalPadding;
		}
	}
	.project-gallery .tile:hover {
		h3 {
			color: $black !important;
		}
	}
}

/* Hidden Function */

.hidden {
	display: none;
}

/* Inline nav */

.inline-nav {
	flex: 0 1 50%;
	max-width: none;
	padding: 0;
	margin: 0;

	li {
		float: left;
		display: block;

		&.active a {
			border-bottom: 1px solid #000;
		}

		a {
			color: $black;
			text-decoration: none;
			margin-right: $unit * 2;
			border-bottom: 1px solid transparent;

			&:hover {
				border-bottom: 1px solid #000;
			}
		}
	}

	&.right {
		display: flex;
		justify-content: flex-end;
		li {
			align-self: flex-end;
		}
	}

	&.small {
		padding-top: 1em;
	}
}

/* Icon */

.icon {
	width: $unit * 3;
	height: $unit * 3;
	fill: none;
	stroke-width: 2.5;

	@include respond-to(small){
		width: $unit * 4;
		height: $unit * 4;
	}
}

/* Icon:Wide */

.icon-wide {
	width: $unit * 2 * 5;
	height: $unit * 3;
	fill: none;
	stroke-width: 2.5;
}

	.intro-text {
		p {
			@extend .x-large;
			@extend .gradient-text;
			margin-top: 2rem;
		}
	}

/* List */

.list {
	flex: 1 1 50%;

	&.narrow {
		flex: 1 1 25%;
	}

	h3 {
		@extend .small;
		@extend .pitch-caps;
	}
}

/* Logo */

.logo {
	height: $logoHeight;
	width: auto;
	transition: fill 1s ease;
	path {
		fill: rgba($dark-grey, 0.7);
	}
	@include respond-to(small){
		height: $logoHeight * 0.8;
	}
}

.logo-white {
	height: 100%;
	width: 75%;
	object-fit: contain;
	fill: #ffffff;
}

/* Page */

.pages {
	h1 {
		@extend .callout;
	}
	h2 {
		@extend .callout;
		padding-top: 2rem;
		margin-top: 0;
		margin-bottom: 0;
	}
	h3 {
		@extend .pitch-caps;
		// margin: 1rem 0 2rem 0;
	}
	h4 {
		@extend .sans-serif;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	img + h4 {
		margin-top: 2rem;
	}
	.content-section {
		margin-top: 2rem;
		p, .paragraph-large p {
			@extend .large;
			.light & {
				color: $white !important;
			}
		}
		.paragraph-normal p {
			@extend .medium;

			.light & {
				color: $white !important;
			}
		}
	}
	.summary-blocks, .project-blocks {
		.tile {
			margin-top: 2rem;
		}
		p {
			margin-top: 0.5rem;
			padding: 0;

			.light & {
				color: $white !important;
			}
		}
	}
	.primary-image-info {
		opacity: 0.5;
		justify-content: flex-start;
		align-items: center;
		p {
			margin-right: 1.0rem;
			.light & {
				color: $white !important;
			}
			&.small {
				margin-top: 1.33em;
				@include respond-to(small){
					margin-top: 0;
					font-size: 0.75rem;
				}
			}
			@include respond-to(small){
				flex: 0 0 100%;
				margin-right: 5%;
			}
		}
		@include respond-to(small){
			align-items: flex-start;
		}
	}
	&.light {
		.intro-text, .content-section, .primary-image-info, .summary-blocks, .project-blocks  {
			h1, h2, h3, h4, p {
				-webkit-text-fill-color: $white;
				color: $white !important;
				background: none !important;
			}
		}
	}
}


/* Map */

#map {
	width: 100vw;
	height: 30vw;
	background: #eee;

	@include respond-to(small){
		height: 75vw;
		touch-action: none;
	}
}

/* Media Page */
.media {
	@include respond-to(small){
		.col.qtr {
			flex: 0 1 100% !important;
			border-right: 0 !important;
		}
	}

}

/* Plyr video player overrides */

.plyr__control{
	border-radius: 50%;
	padding: 8px;
}

.plyr__control--overlaid{
	background: rgba(255, 255, 255, 0.5) !important;
	padding: 16px;
	transform: translate(-50%, -50%) scale(1.5);
}

.plyr--video {
	background: transparent;
}

.plyr--video .plyr__video-wrapper {
	background: transparent;
}

.plyr--video .plyr__controls {
	background: transparent;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover
{
	background: rgba(255, 255, 255, 0.5) !important;
}

.plyr--full-ui input[type=range]
{
	color: #ffffff;
}

/* Pages pagetype */

.pages {
	@extend .container;
	background: $dark-dark-grey;
}

/* Page Title */

.page-title {
	display: flex;
	padding: 0 $horizontalPadding $verticalPadding/2 $horizontalPadding;
	align-content: flex-start;
	justify-content: flex-start;

	h2 {
		@extend .span-sans;
		@extend .gradient-text;
		font-size: 2.75rem;
		font-variation-settings: "wdth" 700, "wght" 90;
		letter-spacing: 0.1em;
		margin: 0;
		padding: 0;
		margin-left: 4px; // HACK!
		flex: 0 0 50%;
		max-width: none;
	}

	ul {
		@extend .inline-nav;
		flex: 0 0 auto;
		padding: 0.5em 0;
		li {
			a {
				color: $grey;
				border-color: rgba($grey, 0.0);
				padding-bottom: 0.25em;

				&:hover {
					border-color: rgba($grey, 1.0);
				}
			}

			&.active a {
				border-color: rgba($grey, 1.0);
				border-width: 1.5px;
				color: rgba($dark-dark-grey, 1.0);
			}
		}
	}

	@include respond-to(small){
		flex-wrap: wrap;
		padding: 0 $mHorizontalPadding $mVerticalPadding * 2;

		h2 {
			flex: 1 1 100%;
			font-size: 8vw;
			padding-bottom: $mVerticalPadding;
		}
		ul {
			flex: 1 1 100%;
			padding: 0.15em 0;

			li {
				a {
					font-size: 0.9rem;
					line-height: 0.8em;
					margin-right: $unit * 1.5;
					margin-bottom: $unit;
				}
			}
		}
	}
}

/* Location pin */

.pin {
  position: absolute;
  border-radius: 50%;
  border: 8px solid $black;
  width: 8px;
  height: 8px;
  margin-top: -25px;
  margin-left: 15px;
	&::after {
	  position: absolute;
	  content: '';
	  width: 0px;
	  height: 0px;
	  bottom: -30px;
	  left: -6px;
	  border: 10px solid transparent;
	  border-top: 17px solid $black;
	}
	&:hover {
	  border: 8px solid $black;
	}
}

/* Popup */

.popup-slide {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap-reverse;
  padding: $verticalPadding $horizontalPadding;

	button {
		flex: 1;
		top: 2.5vw;
		right: 2.5vw;
		color: #fff !important;
		text-align: right !important;
		@include respond-to(small){
			right: 5vw;
		}
	}

	img {
		padding: 0;
		margin: 0;
		flex: 1;
    width: 100%;
		min-width: 100%;
    height: auto;

    &:last-of-type {
			margin-top: 2.5vw;
			padding-top: $verticalPadding;
    }
    &:first-of-type {
			padding-bottom: $verticalPadding;
    }
  }

	p {
		flex: 1;
		width: 50%;
	}
}
.popup-trigger {
	border-bottom: none;
}

/* Preloader */

.progress-bar, .infinite-scroll-last {
  height: $unit;
  width: 100%;
  overflow: hidden;
	background-color: #ccc;
}

.infinite-scroll-last {
	@extend .trim;
}

.progress-bar-value {
  width: 100%;
  height: $unit;
	overflow: hidden;
	text-indent: -2000px;
  background-color: #666;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.2);
  }
  100% {
    transform:  translateX(100%) scaleX(0.25);
  }
}

/* Project: Entry Page */

.project {
	.info {
		h2 {
			@extend .xx-large;
			@extend .gradient-text;
			padding-bottom: 0;
			margin-top: 0;
		}
	}
	.content {
		padding-top: 0;
	}
	.specs {
		flex: 0 0 45%;
		.list {
			flex: 0 0 30%;
		}

		@include respond-to(small){
			order: 2;
			.list {
				flex: 1 1 50%;
			}
		}
	}
	.description {
		@include respond-to(small){
			order: 1;
		}
	}
}

/* Project: Nav */

.project-nav {
	@extend .inline-nav;
	margin: 0;
	display: flex;

	li {
		flex: 0;
		height: $unit * 2.25;
		align-content: center;
		justify-content: center;
		margin: 0 $unit * 2 0 0;

		a {
			display: flex;
			align-content: flex-start;
			padding: 0;
			margin: 0;
			border: 0;
			color: $black;
			display: inline-block;
			flex: 0 0 100%;
			height: $unit * 2.25;

			svg {
				flex: 0;
				display: inline-block;
				stroke: $dark-grey;
				transition: stroke 0.3s ease;
			}

			&:hover {
				border: 0;
				svg {
					stroke: $black;
				}
			}
		}
	}

	.light & {
		li a svg {
			stroke: $white;
		}
	}
}

/* Project Gallery */

.project-gallery {
	margin-top: $unit;
	background: $white;
	z-index: 100;

	.row {
		background: $white;
	}

	.tile {
		padding-bottom: $verticalPadding * 0.75;
		transition: color 0.3s ease;

		@include respond-to(small){
			padding-bottom: $mVerticalPadding;
		}

		h3 {
			color: $black;
		}
	}
}

/* Projects: Sort */

.project-sort {
	@extend .container;
	padding-top: 0 !important;
	h2 {
		@extend .small;
		@extend .pitch-caps;
		color: $black;

		@include respond-to(small){
			font-size: 0.9rem;
		}
	}
	ul {
		li {
			a {
				@extend .xx-large;
				@extend .gradient-text;
				border: none;
				transition: color 0.3s ease;
				&:hover {
					color: $black;
				}
			}
		}
	}
}

/* Related Project */

.related-projects {
	border: 0;

	.tile {
		flex: 0 1 49.90%;
		padding-bottom: $verticalPadding * 0.25;
		transition: color 0.3s ease;

		@include respond-to(small){
			padding-bottom: 0;
		}

		p {
			padding-top: $verticalPadding;
			color: $grey;
			@include respond-to(small){
				padding-top: $mVerticalPadding;
			}
		}
	}
	a.tile:hover {
		p {
			color: $black;
		}
	}

	h3 {
		@extend .small;
		@extend .pitch-caps;
		margin: 0;
		padding: $verticalPadding $horizontalPadding $unit $horizontalPadding;
		color: $dark-grey;

		@include respond-to(small){
			padding: $mVerticalPadding $mHorizontalPadding 0 $mHorizontalPadding;
		}
	}
	.project-title {
		@extend .xx-large;
		margin: 0;
		padding: 0 $horizontalPadding $verticalPadding $horizontalPadding;
		color: $grey;
		@include respond-to(small){
			padding: 0 $mHorizontalPadding $mVerticalPadding $mHorizontalPadding;
		}
	}
}

.related-projects-nav {
	@extend .info;
	position: relative;

	@include respond-to(small){
		padding-bottom: $mVerticalPadding;

		.col.half {
			padding: 0;
		}

		.col.qtr {
			padding-bottom: $mVerticalPadding * 3;
			flex: 1 1 100%;
		}

		.project-nav {
			position: absolute;
			bottom: $mVerticalPadding/2;
			left: $mHorizontalPadding;
		}
	}
}

/* Role */

.role {
	color: $grey;

	.light & {
		color: $light-grey;
	}
}

/* Search */

#search {
	flex: 0 1 0.25%;
	height: $unit * 3;
	display: flex;
	align-self: center;
	justify-self: center;
	align-content: flex-end;
	justify-content: flex-end;
	padding-top: $unit;
	border-bottom: $unit*0.5 solid rgba($black, 0.0);
	transition: border-color 0.6s ease, flex 1s ease;

	.light & {
		.open-search {
			svg {
				stroke: $white;
			}
		}
	}

	@include respond-to(small){
		transition: none;
		margin: 0;
		padding-top: $unit * 0.5;
		z-index: 5000;
	}

	.search-active & {
		flex: 0 1 32%;
		border-color: rgba($black, 1.0);

		.open-search {
			svg {
				stroke: $black;
			}
		}
		.close-search {
			display: block;
			@include respond-to(small){
				display: none;
			}
		}

		form {
			width: auto;
		}

		@include respond-to(small){
			position: absolute;
			top: 50vh;
			left: 0;
			width: 100%;
			border-bottom: 0;

			input {
				font-size: 5vw;
				color: $white;
				&::placeholder {
					color: rgba($white, 0.5);
					padding-left: 3px;
				}
			}
		}
	}

	.search-active.light & {
		border-color: rgba($white, 1.0);

		.open-search, .close-search {
			svg {
				stroke: $white;
			}
		}
		form {
			input {
				&::placeholder {
					color: $white;
				}
			}
		}
	}

	.open-search {
		flex: 0 1 10%;
		align-self: center;
		justify-self: flex-end;
		width: auto;
		height: $unit * 3;

		svg {
			stroke: $grey;
		}

		@include respond-to(small){
			padding: $unit $unit * 0.5;
			z-index: 10;
			svg {
				margin: 0;
			}
		}
	}

	form {
		width: 0;
		flex: 0 1 85%;

		@include respond-to(small){
		}

		label {
			display: none;
		}

		input {
			@extend .medium;
			background: none;
			border: none;
			outline: 0;
			width: 100%;
			height: $unit * 3;
			color: $black;

			&::placeholder {
				color: $black;
			}
		}
	}

	.close-search {
		flex: 0 1 5%;
		display: none;
		height: $unit * 3;
		padding-top: $unit * 0.25;
		svg {
			stroke: $black;
			width: $unit * 1.75;
			height: $unit * 1.75;
		}
	}

	.search {
		transition: stroke 0.3s ease;
		stroke: $dark-grey;

		&:hover {
			stroke: $black;
		}
	}
}

/* Search Results Counter */

.search-results-count {
	@extend .medium;
	font-weight: 300;
	letter-spacing: 0;
	position: relative;
	top: -0.8rem;
	left: $unit/2;
}

/* Section Headers */

.section-header {
	color: $black !important;
	.counter {
		@extend .small;
		color: $black;
		padding-left: $horizontalPadding/4;
	}
}

/* Services Nav */

.services {
	@extend .inline-nav;
	li {
		 a {
			 color: inherit;
			 border-color: inherit;
		 }
	}
}

/* Social Nav */

.social-nav {
	@extend .inline-nav;
	li {
		a {
			color: $light-grey;
			border-bottom: 1px solid $light-grey;
			&:hover {
				color: $white !important;
			}
		}
	}
}

/* Sort */

.sort {
	@extend .small;

	color: $grey;
	display: flex;
	align-content: flex-start;
	justify-content: flex-start;

	li:first-child {
		margin-right: $unit/2;
	}

	@include respond-to(small){
		align-content: flex-start;
		justify-content: flex-start;
		padding-top: $mVerticalPadding/3 !important;
	}
}

/* Sort Nav (Projects) */
.sort-nav {
	display: flex;
	padding: 0 $horizontalPadding $verticalPadding $horizontalPadding;
	align-content: flex-start;
	justify-content: flex-start;
	@include respond-to(small){
		flex-wrap: wrap;
		padding: 0 $mHorizontalPadding $mVerticalPadding;
	}

	ul {
		@extend .inline-nav;
		flex: 0 0 auto;
		padding: 0.5em 0;

		@include respond-to(small){
			flex: 0 0 100%;
			li {
				a {
					font-size: 0.9rem;
					line-height: 0.8em;
					margin-right: $unit * 1.5;
					margin-bottom: $unit;
				}
			}
		}

		li {
			a {
				color: $grey;
				border-color: rgba($grey, 0.0);
				padding-bottom: 0.25em;

				&:hover {
					border-color: rgba($grey, 1.0);
				}
			}

			&.active a {
				color: $black;
				border-color: rgba($grey, 1.0);
			}
		}
	}

	.filters {
		@extend .inline-nav;
		display: flex;
		flex: 0 0 50%;
		padding: 0.5em 0;

		@include respond-to(small){
			flex: 0 0 100%;
		}

		select {
			box-shadow: none !important;
			outline: none;
			-webkit-appearance: none;
			appearance: none;
			background-color: transparent;
			border: none;
			cursor: inherit;
			width: 100%;
			padding: 0;
			&:focus {
				outline: none;
			}
			grid-area: select;
		}

		.select {
			box-shadow: none !important;
			outline: none;
			-webkit-appearance: none;
			appearance: none;
			background-color: transparent;
			width: 120px;
			border: none;
			padding: 0;
			margin: 0 2em 0 0;
			cursor: pointer;
			color: $black;
			border-bottom: 1px rgba($grey, 0.5) solid;
			position: relative;
			display: grid;
			grid-template-areas: "select";
			align-items: center;
			transition: color 0.3s ease, border-color 0.3s ease;
			
			&:hover {
				border-color: rgba($grey, 1.0);	
				color: $black;

				&::after {
					background-color: $black;
				}
			}

			&::after {
			  content: "";
			  width: 0.8em;
			  height: 0.4em;
			  background-color: rgba($grey, 0.8);
			  clip-path: polygon(0 0, 50% 65%, 100% 0, 100% 25%, 50% 100%, 0 25%);
			  grid-area: select;
			  justify-self: end;
			  transition: background-color 0.3s ease
			}

			@include respond-to(small){
				width: 50%;
				font-size: 0.9rem;
				line-height: auto;
			}

		}		
	}
}

/* Team Page */

.team {
	@extend .container;
	background: $dark-dark-grey;

	.row {
		.half {

			img {
				width: 90%;
				height: auto;
				align-self: flex-start;
				@include respond-to(small){
					width: 100%;
				}
			}
			h2 {
				@extend .xx-large;
				margin-top: $unit;
				margin-bottom: $verticalPadding;
			}
			.role {
				@extend .small;
				@extend .pitch-caps;
				color: $white;
				padding-top: 0;
				margin-top: 0;
			}

			h3 {
				@extend .medium;
				font-weight: 300;
				color: $white;
				margin-bottom: $unit;
			}
			p {
				@extend .medium;
				color: $white;
			}
			.details {
				padding: $unit 0 0 0;
				display: flex;
				align-items: flex-start;

				h3 {
					@extend .small;
					@extend .pitch-caps;
					color: $white;
				}
			}

			@include respond-to(small){
				p {
					max-width: none;
				}
			}
		}
	}
}

/* Tile */

.tile {
	flex: 0 0 #{(99.75%)/3};
	margin: 0.0625% 0.0625% 0 0;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border: none;

	h3 {
		@extend .medium;
		color: $black;
		z-index: 1;
		align-self: flex-start;
		margin: 0;
		opacity: 1.0;
		transition: color 0.3s ease;
		padding: $verticalPadding*0.75 $horizontalPadding 0 $horizontalPadding;
		font-weight: 300;
	}

	p {
		@extend .medium;
		color: $black;
		z-index: 1;
		align-self: flex-start;
		margin: 0;
		opacity: 1.0;
		transition: color 0.3s ease;
		padding: 0 $horizontalPadding $verticalPadding*0.75 $horizontalPadding;

		.light & {
			color: $white;
		}
	}

	.thumbnail {
		display: block;
		margin: 0;
		padding: 0;
		background: rgba($grey, 0.1);
		width: 100%;
		height: auto;
		transform: scale(1.0);
		transition: transform 1s ease;
	}

	.img-wrapper {
		overflow: hidden;
	}

	&.logo-bkg {
		width: 100%;
		min-height: 15vw;
		transition: background-color 0.3s ease-in-out;
		&:hover {
			background-color: #fff !important;
		}
		@include respond-to(small){
			min-height: 10vh;
			height: 10vh;
			padding: 5vh 0;
		}
	}

	@include respond-to(small){
		flex: 0 0 100%;
		padding: 0;
		margin: 0;

		h3{
			padding: $mVerticalPadding $mHorizontalPadding 0 $mHorizontalPadding;
		}
		p {
			padding: 0 $mHorizontalPadding $mVerticalPadding $mHorizontalPadding;
		}
		.thumbnail {
			width: 100%;
			object-fit: cover;

			&[alt]{
				color: $grey;
			}
		}
	}
}
a.tile:hover {
	cursor: pointer;

	.thumbnail {
		transform: scale(1.05);
	}
	h3 {
		color: $black;
		text-decoration: underline;
		text-underline-offset: 4px;
	}
	p {
		color: $black;
	}
	.light & {
		h3, p {
			color: $white;
		}
	}
}

/* Toggle Navigation */

.toggle-navigation {
	align-self: center;
	justify-self: flex-end;
	width: $unit * 2.25;
	height: $unit * 3;
	display: none;

	svg {
		position: absolute;
		stroke: $grey;
	}

	.close {
		display: none;
	}

	.search-active & {
		.open {
			display: none !important;
		}
	}

	.light & {
		svg {
			stroke: $white;
		}
	}

	@include respond-to(small){
		z-index: 10;
		display: block;
		padding: 0 $unit * 0.5;
	}
}

/* To Top */
.to-top {
	display: inline;
	width: auto;
	border: none;
	svg {
		width: $unit * 1.75;
		height: $unit * 1.75;
		stroke: $dark-grey;
	}

	.light & {
		svg {
			stroke: $white;
		}
	}

	@include respond-to(small){
		position: absolute;
		bottom: $mVerticalPadding/2;
		right: $mHorizontalPadding * 1.5;
		z-index: 50000;
	}
}


/* Rainbow Progress Bar */

.trim {
	background: $rainbow;
	background-size: 200% 200%;
	width: 100%;
	height: $unit;
	margin:0;
	padding: 0;

	-webkit-animation: rainbow 30s linear infinite;
	-moz-animation: rainbow 30s linear infinite;
  animation: rainbow 30s linear infinite;

	@-webkit-keyframes rainbow {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	@-moz-keyframes rainbow {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	@keyframes rainbow {
		0%{ background-position: 0% 0% }
    50%{ background-position: 100% 50% }
		0%{ background-position: 0% 100% }
	}

	&.top-gradient {
		position:fixed;
		z-index: 50000;
	}

	@include respond-to(small){
		height: $unit * 0.5;
	}
}

/* View all projects */

.view-projects {
	@extend .small;
	@extend .pitch-caps;
	border-color: $grey;
	color: $grey;
	width: auto;
	flex: 0;
}

/* View by Nav */

.view-by-nav {
	@extend .medium;
	.light & {
		li a {
			color: $white !important;
			border-color: rgba($white, 0.5) !important;
			&:hover {
				border-color: rgba($white, 1.0) !important;
			}			
		}
	}
}