html {
	background: $white;
	overflow-x: hidden;
}

div {
	z-index: 1;
}

/* Scrollbar */

body {
	&::-webkit-scrollbar {
  	width: $unit;
	}

	&::-webkit-scrollbar-track {
  	background-color: lighten($light-grey, 15%);
	}

	&::-webkit-scrollbar-thumb {
	  background-color: $dark-dark-grey;
	  outline: 1px solid $dark-dark-grey;
	}

	&.light {
		&::-webkit-scrollbar-track {
	  	background: $dark-grey;
		}

		&::-webkit-scrollbar-thumb {
		  background-color: lighten($light-grey, 15%);
		  outline: 1px solid $dark-dark-grey;
		}
	}
}

header {
	width: #{$overallWidth - $horizontalPadding * 2};
	padding: $verticalPadding $horizontalPadding;
	z-index: 100;
	display: flex;
	align-content: center;
	justify-content: space-between;

	@include respond-to(small){
		padding: $mVerticalPadding $mHorizontalPadding;
		width: #{100vw - $mHorizontalPadding * 2};
		justify-content: inherit;
		align-items: flex-end;
		justify-content: flex-end;

		.nav-active &, .search-active & {
			z-index: 10000;

			h1 {
				svg path {
					fill: $white;
				}
			}

			#search .open-search {
				svg {
					stroke: $white;
				}
			}

			.toggle-navigation {
				svg {
					stroke: $white;
				}
				.close {
					display: inline-block;
				}
				.nav {
					display: none;
				}
			}

			nav {
				opacity: 1.0;
				pointer-events: all;
				.close-navigation {
					opacity: 1.0;
					pointer-events: all;
				}
			}
		}
	}

	a {
		@extend .medium;
		margin: 0;
		font-weight: 500;
		color: $dark-grey;
		border: 0;
	}

	h1 {
		flex: 0 0 50%;
		max-width: none;
		height: $logoHeight;
		align-self: flex-start;
		margin: 0;
		transition: flex 1s ease;

		.hide-logo & {
			display: none;
		}

		.search-active & {
			flex: 0 1 33%;
			@include respond-to(small){
				flex: 0 1 90%;
				transition: none;
			}
		}

		a {
			width: auto;
			height: auto;
			display: inline-block;

			svg {
				@extend .logo;
			}
		}

		@include respond-to(small){
			z-index: 10;
			flex: 1;
			align-self: center;
			justify-content: center;
			height: $logoHeight * 0.8;
			transition: none;

			.hide-logo & {
				display: block;
			}
		}
	}

	nav {
		display: flex;
		flex: 1 0 47.5%;
		align-content: center;
		justify-content: space-between;

		.search-active & {
			@include respond-to(small){
				ul {
					display: none;
				}
			}
		}

		ul {
			float: left;
			height: $unit * 2;
			justify-self: center;
			align-self: center;
			margin-right: $unit * 6;

			li {
				display: block;
				float: left;
				margin-right: $unit * 2;
				width: auto;
				height: auto;

				a {
					display: block;
					text-decoration: none;
					color: $grey;
					border-bottom: 1px rgba($grey, 0%) solid;
					transition: color border-color 0.3s ease;

					&:hover {
						color: $dark-grey;
						border-color: rgba($grey, 100%);
					}
				}

				&.active a {
					border-color: rgba($grey, 100%);
				}
			}
		}

		@include respond-to(small){
			z-index: 2;
			position: absolute;
			flex: 0;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background: $half-rainbow;
			opacity: 0.0;
			pointer-events: none;
			transition: opacity 0.3s ease;

			ul {
				align-self: center;
				padding: $mVerticalPadding $mHorizontalPadding * 0.8;
				height: auto;
				li {
					clear: both;
					a {
						margin-right: 0;
						font-size: 15vw;
						font-weight: 100;
						line-height: 1.2em;
						color: $white !important;
						border: none;
					}
				}
			}
		}

		@include respond-to(small-landscape){
			ul {
				align-self: flex-end;
				justify-self: flex-end;
				padding-left: $mHorizontalPadding;
				padding-bottom: $mVerticalPadding;

				li {
					a {
						font-size: 2rem;
					}
				}
			}
		}
	}

	.light & {
		h1, a {
			color: $white;
		}
		h1 a svg path {
			fill: $white !important;
		}
		nav ul {
			li a {
				border-color: rgba($white, 0.0);
				&:hover {
					color: $white;
					border-color: rgba($white, 1.0);
				}
			}
		}
		.search {
			svg {
				stroke: $white;
			}
			&:hover {
				svg {
					stroke: $white;
				}
			}
		}
	}
}

main {
	min-height: 60vh;

	@include respond-to(small){
		min-height: 40vh;
	}
	@include respond-to(small-landscape){
	}
}

.grid {
	width: #{$overallWidth};
	padding: 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	@include respond-to(small){
		width: #{$overallWidth};
		padding: 0;
		padding-bottom: $mVerticalPadding;
	}

	&.set {
		flex-flow: wrap;

		img {
			width: 100%;
			height: auto;
		}

		@include respond-to(small){
			padding-bottom: 0;
		}
	}

	&.container {
		width: #{$overallWidth - $horizontalPadding * 2};
		padding: $horizontalPadding;

		@include respond-to(small){
			width: #{$overallWidth - $mHorizontalPadding * 2};
			padding: $mVerticalPadding $mHorizontalPadding;
		}
	}
}

.row {
	flex: 0 1 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-between;
	margin-bottom: $verticalPadding;
	overflow: hidden;

	&.flush {
		margin-bottom: 0;

		&[data-projects="1"]{
			.tile {
				flex: 0 0 100%;
				width: 100%;

				@include respond-to(small){
					flex: 0 0 100%;
					width: 100%;
					margin: 0;

					img {
						min-height: 50vw;
						width: auto;
					}
				}
			}
		}
		&[data-projects="2"]{
			.tile {
				flex: 0 0 49.92%;
				@include respond-to(small){
					flex: 0 0 100%;
				}
			}
		}
		&[data-project="3"]{
			.tile {
				flex: 0 0 33.3%;
			}
		}
	}

	&.info, &.indented {
		padding-left: $horizontalPadding;
		padding-right: $horizontalPadding;
		align-items: flex-start;

		&.flush-left {
			justify-content: flex-start;
		}

		h2 {
// 			padding-bottom: $verticalPadding;
		}

		@include respond-to(small){
			margin-bottom: 0;
			padding-left: $mHorizontalPadding;
			padding-right: $mHorizontalPadding;
		}
	}

	.wrapper {
		flex: 0 1 100%;
		display: flex;
		justify-content: center;

		@include respond-to(small){
			flex-direction: column;
		}

		&.inset {
			padding: 0 $horizontalPadding * 1.5;

			img {
				padding: 0 $horizontalPadding/2;
			}

			@include respond-to(small){
				padding: 0 $mHorizontalPadding;

				img {
					padding-bottom: $mVerticalPadding;

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.light &.info {
		h2 {
			@extend .gradient-text-inv;
		}
	}

	&[data-assets="2"]{
		img {
			flex: 0 1 50%;
			width: 50%;

			@include respond-to(small){
				flex: 0 1 100%;
				width: 100%;
				margin: 0;
			}
		}
	}

	&[data-assets="3"]{
		img {
			flex: 0 1 33%;
			width: 33%;

			@include respond-to(small){
				flex: 0 1 100%;
				width: 100%;
				margin: 0;
			}
		}
	}

	&[data-assets="4"]{
		img {
			flex: 0 1 50%;
			width: 50%;
			margin-top: $mVerticalPadding;

			@include respond-to(small){
				flex: 0 1 100%;
				width: 100%;
			}
		}
	}

	img {
		align-self: center;
		width: 100%;
		height: auto;
	}

	.video-container {
		width: 100%;
	}

	&.fit-to-height {
		align-content: center;
		justify-content: center;
		flex-wrap: wrap;

		img {
			flex: 0 1 auto;
			width: auto;
			height: 90vh;
			margin: 0 1%;

			@include respond-to(small){
				width: auto;
				height: 100vw;
			}
		}

		.video-container {
			height: 100vh;
			width: auto;
		}

		.inset {
			padding: $verticalPadding * 1.5 $horizontalPadding * 1.5 !important;
			@include respond-to(small){
				padding: $mVerticalPadding $mHorizontalPadding;
			}
		}
	}

	@include respond-to(small){
		margin-bottom: $mVerticalPadding;
	}
}

.col {
	flex: 0 1;
	margin: 0;
	display: flex;
	flex-direction: column;

	&.border-right {
		border-right: 1px #000 solid;
		flex: 0 1 24.85% !important;
	}

	&.container {
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		h1, h2, h3, h4, h5, h6, p, ol, ul {
			display: block;
			width: 100%;
		}

		@include respond-to(small){
			flex: 0 1 100% !important;
		}
	}

	&.full {
		flex: 0 1 100%;
	}
	&.three-qtr {
		flex: 0 1 75%;
		@include respond-to(small){
			flex: 0 1 100%;
			padding-bottom: $mVerticalPadding;
		}
	}
	&.half {
		flex: 0 1 50%;

		@include respond-to(small){
			flex: 0 1 100%;
			padding-bottom: $mVerticalPadding;
		}
	}
	&.third {
		flex: 0 1 30%;

		@include respond-to(small){
			flex: 0 1 100%;
			padding-bottom: $mVerticalPadding;
		}
	}
	&.qtr {
		flex: 0 1 25%;

		@include respond-to(small){
			flex: 0 1 50%;
		}
	}

}


footer {
	@extend .grid;
	width: #{$overallWidth - $horizontalPadding * 2};
	padding: $verticalPadding $horizontalPadding;
	background: $dark-dark-grey;
	color: $white;

	@include respond-to(small){
		width: #{$overallWidth - $mHorizontalPadding * 2};
		padding: $mVerticalPadding $mHorizontalPadding;
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.col.half {
		flex-direction: row;
		flex-grow: flex-start;
		p {
			a {
				border: none;
				svg {
					@extend .logo;
					path {
						fill: $white !important;
					}
				}
			}
			.footer-image {
				@extend .logo;
			}

			@include respond-to(small){
				margin-right: $mHorizontalPadding * 2;
			}
		}

		@include respond-to(small){
			flex: 0 !important;
			height: auto;
			margin-top: $mVerticalPadding;
			padding-bottom: 0;
		}
	}

	.col.qtr {
		p {
			margin-top: 0;
		}

		@include respond-to(small){
			flex: 0 1 100% !important;
			width: 100%;
			margin-bottom: $unit;
			p {
				max-width: none;
			}
		}
	}

	h3 {
		@extend .small;
		@extend .pitch-caps;
		color: $light-grey;
		margin: 0;
		padding-bottom: 0;
	}

	address {
		color: $light-grey;
	}

	p {
		color: $light-grey;
		margin-right: $horizontalPadding;

		a {
			color: $light-grey;
			border-color: $light-grey;
		}
	}

	.light & {
		h3, p, a, address, ol li, ul li {
			color: $light-grey;
			border-color: $light-grey;
		}
	}
}

body.nav-active {
	position: fixed;

	.top-gradient {
		display: none;
	}
}

body.search-active {
	@include respond-to(small){
		position: fixed;

		.top-gradient {
			display: none;
		}
	}
}
