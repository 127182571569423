
/* Sizes and Spacing */
$overallWidth: 100.0vw;
$verticalPadding: 2.5vw;
$horizontalPadding: 2.5vw;
$mVerticalPadding: $verticalPadding * 2;
$mHorizontalPadding: $horizontalPadding * 2;
$logoHeight: 60px;
$unit: 9px;

/* Colors */
$black: #000;
$x-light-grey: lighten(#A7A2A2, 20%);
$light-grey: lighten(#A7A2A2, 15%);
$grey: darken(#A7A2A2, 10%);
$dark-grey: darken($grey, 20%);
$dark-dark-grey: darken($grey, 50%);
$white: #ffffff;

/* Gradients */
$half-rainbow: linear-gradient(60deg, rgba(#06A1D7, 0.7), rgba(#674795, 0.8), rgba(#702077, 0.9), rgba(#D8433F, 1.0), rgba(#FEBE2B, 1.0), rgba(#FFFFFF, 1.0), rgba(#B2ADAD, 0.8), rgba(#000000, 1.0));
$rainbow: linear-gradient(90deg, #000000, #B2ADAD, #FFFFFF, #FEBE2B, #D8433F, #702077, #674795, #06A1D7, #45B973, #06A1D7, #674795, #702077, #D8433F, #FEBE2B, #FFFFFF, #B2ADAD, #000000);
$grey-gradient: linear-gradient(30deg, #333, #ccc, #333);
$white-gradient: linear-gradient(30deg, #fff, #fff, rgba(255, 255, 255, 0.4), #fff, #fff);

/* Breakpoints */
$break-small: 900px;
$break-large: 1100px;

@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == small-landscape {
    @media only screen and (max-width: $break-small) and (orientation: landscape) { @content; }
  }
  @else if $media == medium {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide {
    @media only screen and (min-width: $break-large) { @content; }
  }
}

/* Custom properties */

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
