@font-face {
    font-family: 'AtlasGrotesk';
		src: url('../fonts/AtlasGrotesk/AtlasGrotesk-Bold.otf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'AtlasGrotesk';
		src: url('../fonts/AtlasGrotesk/AtlasGrotesk-Light.otf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'AtlasGrotesk';
		src: url('../fonts/AtlasGrotesk/AtlasGrotesk-LightItalic.otf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'AtlasGrotesk';
		src: url('../fonts/AtlasGrotesk/AtlasGrotesk-Thin.otf');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'AtlasGrotesk';
		src: url('../fonts/AtlasGrotesk/AtlasGrotesk-ThinItalic.otf');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Pitch';
		src: url('../fonts/Pitch/Pitch-Medium.otf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Span';
		src: url('../fonts/SpanSans/spansans.ttf');
    font-weight: 500;
    font-style: normal;
}


html {
	font-family: 'AtlasGrotesk', 'Helvetica', sans-serif;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	word-spacing: 0.03em;
	font-display: swap;
}

.caps {
	text-transform: uppercase;
	letter-spacing: 0.075em;
}

.small {
	font-size: 0.75rem;
	color: $black;

	.light & {
		color: $white;
	}

	@include respond-to(small){
		font-size: 0.9rem;
	}
}

.medium {
	font-size: 1.0rem;
	line-height: 1.65em;
	max-width: 650px;
	margin-bottom: 0.5rem;
	color: $black;

	.light & {
		color: $white;
	}

	@include respond-to(small){
		font-size: 1.0rem;
		max-width: none;
	}
}

.large {
	font-size: 2rem;
	line-height: 1.333em;
	max-width: 750px;

	.light & {
		color: $white;
	}

	@include respond-to(small){
		font-size: 1.5rem;

		&:first-child {
			margin-top: 0;
		}
	}
}
.x-large {
	font-size: 2.5rem;
	line-height: 1.333em;
	max-width: 50vw;
	word-spacing: -0.01em;
	padding-bottom: 0;
	margin-bottom: 0;

  .light & {
		color: $white;
	}

	@include respond-to(small){
		font-size: 2rem;
		max-width: none;
	}
}

.xx-large {
	font-size: 4.0rem;
	font-weight: 100;
	line-height: 1.2em;
	max-width: 55vw;
	word-spacing: -0.01em;
	letter-spacing: -0.02em;

  .light & {
		color: $white;
	}

	@include respond-to(medium){
		font-size: 3.75rem;
	}

	@include respond-to(small){
		font-size: 2.2rem;
		line-height: 1.25em;
		max-width: none;
	}
}

.pitch-caps {
	font-family: 'Pitch', 'Courier', monospace;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	word-spacing: -0.25em;
}

.span-sans {
	font-family: 'Span', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
}

.sans-serif {
  font-family: 'AtlasGrotesk', 'Helvetica', sans-serif;
  font-weight: 300;
  text-transform: none;
  word-spacing: 0.03em;
  letter-spacing: 0;
}

.extended {
  .light & {
		color: $white;
	}

	font-variation-settings: "wdth" 1400, "wght" 90;
	@include respond-to(small){
		font-variation-settings: "wdth" 700, "wght" 90;
	}
}

h1 {
	@extend .large;
}

h2 {
	@extend .large;
	font-weight: 300;
	strong {
		font-weight: 600;
	}
	&.project-title {
		margin-bottom: 0;
	}
}

h3, p {
	@extend .medium;
	&.project-commission {
		margin-top: 0;
		color: rgba($black, 0.5);

		.light & {
			color: rgba($white, 0.5);
		}
	}
	&.large {
		@extend .large;
	}
}
h4 {
	@extend .pitch-caps;
	.light & {
		color: $white;
	}
}

blockquote {
	@extend .medium;
	font-style: italic;
	&::before {
		content: "\201C";
		margin-left: -0.45rem;
	}

	@include respond-to(small){
		margin: 4vw;
	}
}

dl {
	margin: 0 0 $unit 0;
	dt {
		@extend .small;
		@extend .pitch-caps;

		color: rgba($black, 0.5);
		margin: 0 0 $unit 0;
		padding: 0;

		.light & {
			color: rgba($white, 0.9);
		}
	}
	dd {
		@extend .medium;
		color: $black;
		margin: 0;
		padding: 0;

		.light & {
			color: $white;
		}
	}

	&.awards {
		dd {
			margin-bottom: $unit;
		}
	}
}

ul {
	@extend .medium;
	list-style: none;
	padding: 0;
}

ol {
	@extend .medium;
	padding: 0;
}

li {
	.light & {
		color: $white;
	}
}

a {
	color: $black;
	text-decoration: none;
	border-bottom: 1px rgba($black, 0.6) solid;
	padding-bottom: 1px;
	transition: border-color 0.25s ease-in-out;

	&:hover {
		border-color: rgba($black, 0.0);
	}

	.light & {
		color: $white;
		border-color: rgba($white, 0.6);
		&:hover {
			border-color: rgba($white, 0.0);
		}
	}

	&.hide-line {
		border-bottom: none !important;
		&:hover {
			border-bottom: none !important;
		}
	}
}

address {
	@extend .medium;
	font-style: normal;
}
